<script setup lang="ts">
// types
import type {
  Consult,
  ContactActivity,
  Nullable
} from '@revolutionprep/types'
import type { FetchError } from 'ofetch'
import type {
  ValidationOptions,
  FormValidationResult,
  GenericObject
} from 'vee-validate'

// validation
import { date, number, string } from 'yup'

// utilities
import {
  dateWithOffset,
  errorsToSentence,
  isEmpty
} from '@revolutionprep/utils'
import { formatISO, subWeeks } from 'date-fns'
import { format } from 'date-fns-tz'

// stores
import { useConsultStore } from '@/store/consult'

// composables
import { useConsult } from '@/composables/consult'

/**
 * props
 * ==================================================================
 */
interface Props {
  consultData: Consult
  validationErrors?: Partial<Record<string, string | undefined>>
  formValidationFunction:
    (opts?: Partial<ValidationOptions> | undefined) => Promise<FormValidationResult<GenericObject, GenericObject>>
}
const props = defineProps<Props>()

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits(['is-processing'])

/**
 * nuxt app
 * ==================================================================
 */
const { $toast } = useNuxtApp()

/**
 * composables
 * ==================================================================
 */
const { outcomes } = useConsult()

const { doHandleError } = useErrorHandler()

const {
  isProcessing,
  toggleIsProcessing
} = useForms()

const { hasErrors } =
  useValidationErrors(toRef(() => props.validationErrors || null))

/**
 * stores
 * ==================================================================
 */
const consultStore = useConsultStore()

/**
 * state
 * ==================================================================
 */
const noPitchReasons = ref([
  'Too young - not ready',
  'Prepping on own',
  'Already have a tutor'
])
const pitchedProducts = ([
  'Private Tutoring',
  'Small Group Course',
  'Tutoring'
])
const showCancelDialog = ref(false)
const imminentCloseValue = ref(false)
const imminentClose = ref<Nullable<Partial<ContactActivity>>>(null)

/**
 * computed
 * ==================================================================
 */
const consultPayload = computed(() => {
  return {
    ...props.consultData,
    employeeId: props.consultData.employee?.id || props.consultData.parent?.employeeId,
    outcome: consultOutcome.value,
    noPitchReason: consultNoPitchReason.value,
    pitchedProduct: consultPitchedProduct.value,
    imminentAmount: consultAmount.value,
    imminentClose: imminentCloseValue.value,
    imminentDate:
      consultDate.value ? formatConsultDate(consultDate.value) : undefined,
    notes: consultNotes.value
  }
})

const imminentCloseLabel = computed(() => {
  return imminentCloseValue.value ? 'Yes' : 'No'
})

const min = computed(() => {
  return subWeeks(new Date(), 1)
})

/**
 * methods
 * ==================================================================
 */
function formatConsultDate (consultDate: Date) {
  return formatISO(consultDate)
}

function openCancelDialog () {
  if (!hasErrors.value) {
    toggleCancelDialog(true)
  }
}

function resetFields () {
  resetPitchedProduct({ value: null })
  resetNoPitchReason({ value: null })
  resetAmount({ value: null })
  resetDate({ value: undefined })
  imminentCloseValue.value = Boolean(props.consultData?.imminentClose)
}

function toggleCancelDialog (value: boolean) {
  showCancelDialog.value = value
}

async function updateConsult () {
  try {
    const { errors } = await props.formValidationFunction()
    if (!isEmpty(errors)) {
      $toast.error(errorsToSentence(errors, 'vee-validate'))
      return
    }
    if (!hasErrors.value) {
      toggleIsProcessing(true)
      emit('is-processing', true)
      await consultStore.update(
        props.consultData.id,
        consultPayload.value,
        {
          method: 'PUT',
          params: {
            include:
              'student.school,student.study_areas.*,student.properties,parent,parent.source_link,parent.students.school,imminent_close'
          }
        }
      )
      $toast.success('Your recommendation was updated successfully')
    }
  } catch (error) {
    await doHandleError(error as FetchError)
  } finally {
    toggleIsProcessing(false)
    emit('is-processing', false)
  }
}

/**
 * setup
 * ==================================================================
 */
// set initial state of imminentClose switch
imminentCloseValue.value = Boolean(props.consultData?.imminentClose)

// copy imminentClose object to data
imminentClose.value = props.consultData?.imminentClose
  ? props.consultData.imminentClose
  : { revenue: null, sentAt: null } as Partial<ContactActivity>

// convert revenue to a number
const revenue = imminentClose.value?.revenue

if (imminentClose.value && revenue !== null && revenue !== undefined) {
  imminentClose.value.revenue = Number(revenue)
}
// format sentAt date
const sentAt = imminentClose.value?.sentAt
if (imminentClose.value && sentAt !== null && sentAt !== undefined) {
  imminentClose.value.sentAt = format(dateWithOffset(sentAt), 'yyyy-MM-dd')
}

/**
 * validation
 * ==================================================================
 */
// dynamic validation schema
const consultNoPitchSchema = computed(() => {
  return consultOutcome.value === 'No Recommendation'
    ? string().required().label('Reason for no recommendation')
    : string().notRequired()
})
const consultPitchedProductSchema = computed(() => {
  return consultOutcome.value === 'Recommendation'
    ? string().required().label('Product recommendation')
    : string().notRequired()
})
const consultAmountSchema = computed(() => {
  return imminentCloseLabel.value === 'Yes'
    ? number().required().label('Amount')
    : number().notRequired()
})
const consultDateFieldSchema = computed(() => {
  return imminentCloseLabel.value === 'Yes'
    ? date().required().label('Date')
    : date().notRequired()
})

const {
  value: consultOutcome,
  errorMessage: consultOutcomeError,
  meta: consultOutcomeMeta,
  validate: validateConsultOutcome
} = useField('consultOutcome', string().required().label('Outcome'), {
  initialValue: props.consultData.outcome
})
const {
  value: consultNoPitchReason,
  errorMessage: consultNoPitchReasonError,
  meta: consultNoPitchReasonMeta,
  validate: validateNoPitchReason,
  resetField: resetNoPitchReason
} = useField('consultNoPitchReason', consultNoPitchSchema, {
  initialValue: props.consultData.noPitchReason
})
const {
  value: consultPitchedProduct,
  errorMessage: consultPitchedProductError,
  meta: consultPitchedProductMeta,
  validate: validatePitchedProduct,
  resetField: resetPitchedProduct
} = useField('consultPitchedProduct', consultPitchedProductSchema, {
  initialValue: props.consultData.pitchedProduct
})
const {
  value: consultAmount,
  errorMessage: consultAmountError,
  meta: consultAmountMeta,
  validate: validateAmount,
  resetField: resetAmount
} = useField('consultAmount', consultAmountSchema, {
  initialValue: imminentClose.value?.revenue
})
const {
  value: consultDate,
  errorMessage: consultDateError,
  validate: validateDate,
  resetField: resetDate
} = useField('consultDate', consultDateFieldSchema, {
  initialValue: imminentClose.value?.sentAt
    ? dateWithOffset(imminentClose.value.sentAt)
    : new Date()
})
const {
  value: consultNotes,
  meta: consultNotesMeta,
  errorMessage: consultNotesError
} = useField('consultNotes', string().notRequired(), {
  initialValue: props.consultData.notes
})
</script>

<template>
  <v-card>
    <v-form @submit.prevent="updateConsult">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <lazy-r-select-field
              v-bind="consultOutcomeMeta"
              v-model="consultOutcome"
              :error-messages="consultOutcomeError"
              name="Outcome"
              :form-validation="true"
              vid="outcome"
              label="Outcome"
              hide-details
              hide-selected
              :items="outcomes"
              data-test="select-outcome"
              @input-update-value="resetFields()"
              @blur="validateConsultOutcome()"
            />
          </v-col>
          <v-col
            v-if="consultOutcome === 'No Recommendation'"
            cols="12"
          >
            <lazy-r-select-field
              v-bind="consultNoPitchReasonMeta"
              v-model="consultNoPitchReason"
              :error-messages="consultNoPitchReasonError"
              name="Reason for no recommendation"
              :form-validation="true"
              vid="noPitchReason"
              label="Reason"
              hide-details
              hide-selected
              :items="noPitchReasons"
              @blur="validateNoPitchReason()"
            />
          </v-col>
          <v-col
            v-if="consultOutcome === 'Recommendation'"
            cols="12"
          >
            <lazy-r-select-field
              v-bind="consultPitchedProductMeta"
              v-model="consultPitchedProduct"
              :error-messages="consultPitchedProductError"
              name="Product recommendation"
              :form-validation="true"
              vid="pitchedProduct"
              label="Product"
              hide-details
              hide-selected
              :items="pitchedProducts"
              @blur="validatePitchedProduct()"
            />
          </v-col>
          <v-col
            v-if="consultOutcome === 'Recommendation'"
            cols="12"
          >
            <div class="text-body-2 text-textdarkgrey">
              Imminent close?
            </div>
            <v-switch
              v-if="!props.consultData.imminentClose"
              v-model="imminentCloseValue"
              inset
              density="compact"
              hide-details
              :label="imminentCloseLabel"
              class="mt-1"
            />
            <div
              v-else
              class="text-body-1"
            >
              Yes
            </div>
          </v-col>
          <v-col
            v-if="
              consultOutcome === 'Recommendation'
                && imminentCloseValue
                && imminentClose"
            cols="12"
          >
            <div class="mb-1">
              <lazy-r-input-text-field
                v-bind="consultAmountMeta"
                v-model="consultAmount"
                :error-messages="consultAmountError"
                name="Amount"
                :form-validation="true"
                vid="imminentAmount"
                type="number"
                label="Amount"
                :persist="true"
                @blur="validateAmount()"
              />
            </div>
            <lazy-r-input-date-field
              v-model="consultDate"
              :error-messages="consultDateError"
              name="Date"
              :form-validation="true"
              vid="imminentDate"
              label="Date"
              :min="min"
              @input="consultDate = $event"
              @input-update-value="validateDate()"
            />
          </v-col>
          <v-col cols="12">
            <r-input-text-area
              v-bind="consultNotesMeta"
              id="input-consult-notes"
              v-model="consultNotes"
              :error-messages="consultNotesError"
              name="Recommendation notes"
              :form-validation="true"
              vid="notes"
              label="Notes"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-3">
        <v-btn
          size="small"
          data-test="btn-cancel-consult"
          variant="outlined"
          @click.stop="openCancelDialog"
        >
          Cancel
        </v-btn>
        <LazyDialogCancelConsult
          v-if="props.consultData"
          :show="showCancelDialog"
          :consult-data="props.consultData"
          @toggle-dialog="toggleCancelDialog"
        />
        <v-spacer />
        <v-btn
          color="primary"
          data-test="btn-submit-consult"
          size="small"
          variant="flat"
          type="submit"
          :disabled="isProcessing"
        >
          <lazy-r-spinner v-if="isProcessing" />
          <span v-if="isProcessing">
            Completing...
          </span>
          <span v-if="!isProcessing">
            Complete
          </span>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
