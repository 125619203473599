<script setup lang="ts">
//  vuetify
import { useDisplay } from 'vuetify'

// types
import type { UserRole } from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// stores
import { useConsultStore } from '@/store/consult'
import { useGlobalStore } from '@/store/global'

// composables
import { usePage } from '@/composables/page'

/**
 * page metadata
 * ==================================================================
 */
definePageMeta({
  layout: 'blank',
  name: 'ConsultPage',
  middleware: ['validate-route-roles'],
  meta: {
    roles: [
      'operations',
      'advising_manager',
      'academic_advisor'
    ] as UserRole[]
  }
})

/**
 * vuetify
 * ==================================================================
 */
const { mdAndUp } = useDisplay()

/**
 * route
 * ==================================================================
 */
const route = useRoute()

/**
 * state
 * ==================================================================
 */
const isLoading = ref(true)
const title = ref('Consult')

/**
 * stores
 * ==================================================================
 */
const consultStore = useConsultStore()
const { consult } = storeToRefs(consultStore)

const examTranscriptSummaryStore = useExamTranscriptSummaryStore()

const globalStore = useGlobalStore()
const { pageTitle } = storeToRefs(globalStore)

const gradeLevelsStore = useGradeLevelsStore()
const { gradeLevels } = storeToRefs(gradeLevelsStore)

const parentStore = useParentStore()
const { parent } = storeToRefs(parentStore)

const propertiesStore = usePropertiesStore()

const studentStore = useStudentStore()
const { student } = storeToRefs(studentStore)

const studyAreaStore = useStudyAreaStore()
const { studyAreas } = storeToRefs(studyAreaStore)

const subjectStore = useSubjectStore()
const { subjects } = storeToRefs(subjectStore)

const timeZoneStore = useTimeZoneStore()

/**
 * composables
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()
usePage(title)

/**
 * form validation
 * ==================================================================
 */
const { errors, validate } = useForm()

/**
 * lifecycle hooks
 * ==================================================================
 */
onMounted(() => {
  isLoading.value = false
})

/**
 * page setup
 * ==================================================================
 */
pageTitle.value = title.value

/**
 * data fetching
 * ==================================================================
 */
const { error } = await useLazyAsyncData('consult-page',
  async () => {
    try {
      const _consult = await consultStore.show(
        String(route.params.consultId),
        {
          params: {
            include: 'student.school,student.study_areas.*,student.properties,parent,parent.source_link,parent.students.school,imminent_close,employee'
          }
        }
      )
      if (_consult?.parent) {
        parent.value = _consult.parent
      }
      if (_consult?.student) {
        student.value = _consult.student
      }
      if (_consult?.student?.studyAreas) {
        studyAreas.value = _consult.student.studyAreas
      }
      await Promise.all([
        gradeLevelsStore.index({
          params: {
            withGradYear: true
          }
        }),
        parentStore.fetchReferralSources({
          params: {
            assignable: true
          }
        }),
        studyAreaStore.index({
          params: {
            studentId: _consult.student?.id,
            archived: true,
            include: 'last_snapshot,snapshots,subject'
          }
        }),
        subjectStore.index({
          params: {
            grade: _consult.student?.grade,
            excludeCategoryId: [7]
          }
        }),
        examTranscriptSummaryStore.index({
          headers: {
            Authorization: student.value?.examsApiToken || ''
          }
        }),
        propertiesStore.index(),
        timeZoneStore.index()
      ])
    } catch (error) {
      doHandleError(error as FetchError)
    }
  }
)
</script>

<template>
  <div>
    <v-container>
      <v-row v-if="error">
        <v-col>
          <r-error-display :error="error" />
        </v-col>
      </v-row>
      <v-row v-else-if="isLoading">
        <v-col
          cols="12"
          md="8"
        >
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader type="heading" />
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader type="list-item-two-line" />
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader
                type="heading, divider, list-item-three-line, list-item-two-line, actions"
              />
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader
                type="heading, divider, list-item-three-line, list-item-two-line, actions"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-skeleton-loader type="article, actions" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          cols="12"
          md="8"
        >
          <header
            v-if="consult"
            class="my-6"
          >
            <div class="text-h4 font-weight-bold mb-3">
              {{ consult?.topic }}
            </div>
          </header>
          <r-card-validation-errors
            :validation-errors="errors"
            class="my-6"
          />
          <client-only>
            <LazyCardEditParent
              v-if="parent"
              :parent="parent"
            />
            <lazy-r-card-edit-student
              v-if="student"
              :grade-levels="gradeLevels"
              :student="student"
            />
          </client-only>
          <LazyCardEditSiblings
            v-if="parent && student"
            :grade-levels="gradeLevels"
            :parent="parent"
            :student="student"
          />
          <lazy-r-card-edit-notes
            v-if="student"
            :student="student"
          />
          <lazy-r-card-edit-academics
            v-if="student"
            :student="student"
            :study-areas-data="studyAreas"
            :subjects-data="subjects"
          />
          <client-only>
            <lazy-r-card-edit-test-prep
              v-if="student"
              :student="student"
              :study-areas="studyAreas"
              :subjects="subjects"
            />
          </client-only>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div :class="`${mdAndUp && 'sticky-sidebar mt-6'}`">
            <CardEditConsult
              v-if="consult"
              :form-validation-function="validate"
              :validation-errors="errors"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.sticky-sidebar {
  position: fixed;
  margin-right: 14px;
  width: 26vw;
}

@media (max-width: 960px) {
  .sticky-sidebar {
    width: 31vw;
  }
}

.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-leave-active {
  transition: all .4s ease;
}

.slide-fade-enter {
  transform: translateY(50px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
</style>
